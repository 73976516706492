import React from 'react';
import { WideContainer } from 'components/mui/containers/PxftContainer';
import { Grid, Grow, Slide, Button,Link } from '@mui/material';
import { useInView } from 'react-intersection-observer'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import G2Button from 'components/mui/buttons/G2Button';
import HighPerformanceBadgeButton from 'components/mui/buttons/HighPerformanceBadgeButton';

const Header = () => {
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const containerRef = React.useRef(null);
    return (
        <WideContainer>
            <div className='center-align'>
                <Grid container className='basic-header-container'>
                    <Slide timeout={{ enter: 1200 }} in={inView} direction="right" container={containerRef.current}>
                        <Grid item xs={12} sm={12} md={6} lg={5} className='center-align'>
                            <div className='overview-title'>
                                <p style={{ padding: '10px 0', fontSize: 20, color: 'var(--ynt-purple)' }}>#1 AI model creation platform</p>
                                <h1>Personalized AI that empowers you to improve productivity</h1>
                                <div style={{ padding: '10px 0' }}>
                                    <p>- Simple intuitive user interface</p>
                                    <p>- Custom knowledge AI model training in minutes</p>
                                    <p>- No coding or special skills required</p>
                                </div>
                                <Grid display='flex' flexDirection={isMobile?'column':'row'} alignItems='center'>
                                    <Link href='https://younet.ai/portal/sign-up' pr={{xs:0,md:2}}><Button sx={{ margin: '10px 0', padding: '12px' }} variant='contained'>Sign up for free</Button></Link>
                                    <Grid display='flex' alignItems='center'>
                                        <Link href='https://www.g2.com/products/younet/reviews' underline='none' target='_blank'><G2Button/></Link>
                                        <Link href='https://www.g2.com/products/younet/reviews' underline='none' target='_blank'><HighPerformanceBadgeButton/></Link>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Slide>
                    <Grow timeout={{ enter: 1200 }} in={inView}>
                        <Grid item xs={12} sm={12} md={6} lg={7} className='center-align'>
                            <div className='overview-header-pic center-align'>
                                {isMobile ? <iframe width="300" height="170" src="https://www.youtube.com/embed/DqIL_28fEe0?si=wiCeZTNXbg9rM1SL?&controls=0&hd=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                    : <iframe width="840" height="472.5" src="https://www.youtube.com/embed/DqIL_28fEe0?si=wiCeZTNXbg9rM1SL?&controls=0&hd=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>}
                            </div>
                        </Grid>
                    </Grow>
                    <div className='long-observer' ref={inViewRef}></div>
                </Grid>
            </div>
        </WideContainer>
    );
}

export default Header;